import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-mini-chart-sensor',
  templateUrl: './mini-chart-sensor.component.html',
  styleUrls: ['./mini-chart-sensor.component.scss'],
  animations: [
    trigger('animation', [
      state('visible', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition('void => *', [
        style({ transform: 'translateY(50%)', opacity: 0 }),
        animate('200ms ease-out')
      ]),
      transition('* => void', [
        animate(('200ms ease-in'), style({
          height: 0,
          opacity: 0,
          transform: 'translateY(50%)'
        }))
      ])
    ])
  ]
})
export class MiniChartSensorComponent implements OnInit {

  @Input()
  selectionViewType: string = '';

  @Input()
  componentType: string = '';

  weekDay: any;
  chartBGColor: any;
  chartBorderColor: any;

  datatemp01: any;
  datatemp02: any;
  datatemp03: any;
  datatemp04: any;
  datatemp05: any;
  datatemp06: any;
  datatemp07: any;
  datatemp08: any;
  datatemp09: any;
  datatemp10: any;
  datatemp11: any;
  datatemp12: any;

  datahumidity01: any;
  datahumidity02: any;
  datahumidity03: any;
  datahumidity04: any;
  datahumidity05: any;
  datahumidity06: any;

  dataCO01: any;
  dataCO02: any;
  dataCO03: any;
  dataCO04: any;
  dataCO05: any;
  dataCO06: any;


  optionsMiniBarChart: any;

  miniChartData: any;

  constructor() { }

  ngOnInit(): void {

    this.weekDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturdays', 'Sunday'];

    this.chartBGColor = [
      'rgba(255, 0, 0, 0.4)',
      'rgba(255, 127, 14, 0.4)',
      'rgba(255, 218, 2, 0.4)',
      'rgba(145, 208, 23, 0.4)',
      'rgba(0, 153, 0, 0.4)',
      'rgba(26, 144, 129, 0.4)',
      'rgba(0, 204, 204, 0.4)',
      'rgba(0, 128, 255, 0.4)',
      'rgba(0, 0, 255, 0.4)',
      'rgba(127, 0, 255, 0.4)',
      'rgba(255, 0, 255, 0.4)',
      'rgba(204, 0, 102, 0.4)',
      'rgba(102, 40, 0, 0.4)',
      'rgba(51, 0, 102, 0.4)',
      'rgba(102, 0, 51, 0.4)',
      'rgba(0, 51, 102, 0.4)',
      'rgba(32, 32, 32, 0.4)',
      'rgba(160, 160, 160, 0.4)',
    ];

    this.chartBorderColor = [
      'rgba(255, 0, 0, 0.5)',
      'rgba(255, 127, 14, 0.5)',
      'rgba(255, 218, 2, 0.5)',
      'rgba(145, 208, 23, 0.5)',
      'rgba(0, 153, 0, 0.5)',
      'rgba(26, 144, 129, 0.5)',
      'rgba(0, 204, 204, 0.5)',
      'rgba(0, 128, 255, 0.5)',
      'rgba(0, 0, 255, 0.5)',
      'rgba(127, 0, 255, 0.5)',
      'rgba(255, 0, 255, 0.5)',
      'rgba(204, 0, 102, 0.5)',
      'rgba(102, 40, 0, 0.5)',
      'rgba(51, 0, 102, 0.5)',
      'rgba(102, 0, 51, 0.5)',
      'rgba(0, 51, 102, 0.5)',
      'rgba(32, 32, 32, 0.5)',
      'rgba(160, 160, 160, 0.5)',
    ];

    // ========= Temp Data =========
    let tempDataset01 = {
      label: 'Temp. Sensor 01 [°C]',
      backgroundColor: this.chartBGColor[0],
      borderColor: this.chartBorderColor[0],
      data: [23.5, 24.8, 25.2, 23.1, 22.2, 23.7, 22.9],
      fill: false,
      tension: 0
    }
    let tempDataset02 = {
      label: 'Temp. Sensor 02 [°C]',
      backgroundColor: this.chartBGColor[1],
      borderColor: this.chartBorderColor[1],
      data: [22.1, 22.8, 23.2, 23.1, 22.6, 23.7, 24.1],
      fill: false,
      tension: 0
    }
    let tempDataset03 = {
      label: 'Temp. Sensor 03 [°C]',
      backgroundColor: this.chartBGColor[2],
      borderColor: this.chartBorderColor[2],
      data: [24.5, 24.8, 25.2, 24.7, 23.2, 23.7, 23.9],
      fill: false,
      tension: 0
    }
    let tempDataset04 = {
      label: 'Temp. Sensor 04 [°C]',
      backgroundColor: this.chartBGColor[3],
      borderColor: this.chartBorderColor[3],
      data: [23.0, 22.8, 21.8, 21.9, 22.2, 22.7, 21.9],
      fill: false,
      tension: 0
    }
    let tempDataset05 = {
      label: 'Temp. Sensor 05 [°C]',
      backgroundColor: this.chartBGColor[4],
      borderColor: this.chartBorderColor[4],
      data: [21.1, 21.8, 21.2, 22.1, 21.4, 22.4, 21.9],
      fill: false,
      tension: 0
    }
    let tempDataset06 = {
      label: 'Temp. Sensor 06 [°C]',
      backgroundColor: this.chartBGColor[5],
      borderColor: this.chartBorderColor[5],
      data: [24.5, 24.8, 25.2, 24.5, 24.2, 23.7, 23.9],
      fill: false,
      tension: 0
    }
    let tempDataset07 = {
      label: 'Temp. Sensor 07 [°C]',
      backgroundColor: this.chartBGColor[6],
      borderColor: this.chartBorderColor[6],
      data: [23.1, 23.5, 23.4, 23.6, 23.8, 23.1, 23.7],
      fill: false,
      tension: 0
    }
    let tempDataset08 = {
      label: 'Temp. Sensor 08 [°C]',
      backgroundColor: this.chartBGColor[7],
      borderColor: this.chartBorderColor[7],
      data: [23.8, 23.6, 23.1, 23.5, 23.9, 23.4, 23.1],
      fill: false,
      tension: 0
    }
    let tempDataset09 = {
      label: 'Temp. Sensor 09 [°C]',
      backgroundColor: this.chartBGColor[8],
      borderColor: this.chartBorderColor[8],
      data: [25.3, 24.6, 24.1, 24.3, 24.1, 24.9, 25.3],
      fill: false,
      tension: 0
    }
    let tempDataset10 = {
      label: 'Temp. Sensor 10 [°C]',
      backgroundColor: this.chartBGColor[9],
      borderColor: this.chartBorderColor[9],
      data: [23, 22.4, 23.6, 22.9, 22.5, 23.1, 22.9],
      fill: false,
      tension: 0
    }
    let tempDataset11 = {
      label: 'Temp. Sensor 11 [°C]',
      backgroundColor: this.chartBGColor[10],
      borderColor: this.chartBorderColor[10],
      data: [21.9, 22, 21.6, 22.3, 21.4, 22.8, 22.1],
      fill: false,
      tension: 0
    }
    let tempDataset12 = {
      label: 'Temp. Sensor 12 [°C]',
      backgroundColor: this.chartBGColor[11],
      borderColor: this.chartBorderColor[11],
      data: [20.2, 20.8, 20.6, 20.7, 21, 20.6, 21.2],
      fill: false,
      tension: 0
    }


    // ========= Humidity Data =========
    let HumidityDataset01 = {
      label: 'Humidity Sensor 01 [%]',
      backgroundColor: this.chartBGColor[0],
      borderColor: this.chartBorderColor[0],
      data: [78, 68, 73, 70, 65, 64, 60],
      fill: false,
      tension: 0
    }
    let HumidityDataset02 = {
      label: 'Humidity Sensor 02 [%]',
      backgroundColor: this.chartBGColor[13],
      borderColor: this.chartBorderColor[13],
      data: [83, 85, 76, 72, 68, 62, 65],
      fill: false,
      tension: 0
    }
    let HumidityDataset03 = {
      label: 'Humidity Sensor 03 [%]',
      backgroundColor: this.chartBGColor[14],
      borderColor: this.chartBorderColor[14],
      data: [68, 72, 65, 69, 66, 61, 63],
      fill: false,
      tension: 0
    }
    let HumidityDataset04 = {
      label: 'Humidity Sensor 04 [%]',
      backgroundColor: this.chartBGColor[15],
      borderColor: this.chartBorderColor[15],
      data: [54, 59, 56, 51, 48, 42, 45],
      fill: false,
      tension: 0
    }
    let HumidityDataset05 = {
      label: 'Humidity Sensor 05 [%]',
      backgroundColor: this.chartBGColor[16],
      borderColor: this.chartBorderColor[16],
      data: [67, 62, 59, 61, 57, 50, 54],
      fill: false,
      tension: 0
    }
    let HumidityDataset06 = {
      label: 'Humidity Sensor 06 [%]',
      backgroundColor: this.chartBGColor[17],
      borderColor: this.chartBorderColor[17],
      data: [48, 45, 51, 47, 53, 50, 49],
      fill: false,
      tension: 0
    }


    // ========= CO2 Data =========
    let CODataset01 = {
      label: 'CO\u2082 Sensor 01 [ppm]',
      backgroundColor: this.chartBGColor[5],
      borderColor: this.chartBorderColor[5],
      data: [127, 135, 145, 123, 134, 124, 149],
      fill: false,
      tension: 0
    }
    let CODataset02 = {
      label: 'CO\u2082 Sensor 02 [ppm]',
      backgroundColor: this.chartBGColor[4],
      borderColor: this.chartBorderColor[4],
      data: [160, 187, 175, 172, 178, 162, 170],
      fill: false,
      tension: 0
    }
    let CODataset03 = {
      label: 'CO\u2082 Sensor 03 [ppm]',
      backgroundColor: this.chartBGColor[3],
      borderColor: this.chartBorderColor[3],
      data: [130, 145, 151, 141, 132, 138, 127],
      fill: false,
      tension: 0
    }
    let CODataset04 = {
      label: 'CO\u2082 Sensor 04 [ppm]',
      backgroundColor: this.chartBGColor[2],
      borderColor: this.chartBorderColor[2],
      data: [156, 168, 189, 210, 197, 187, 195],
      fill: false,
      tension: 0
    }
    let CODataset05 = {
      label: 'CO\u2082 Sensor 05 [ppm]',
      backgroundColor: this.chartBGColor[1],
      borderColor: this.chartBorderColor[1],
      data: [168, 175, 170, 161, 175, 189, 170],
      fill: false,
      tension: 0
    }
    let CODataset06 = {
      label: 'CO\u2082 Sensor 06 [ppm]',
      backgroundColor: this.chartBGColor[0],
      borderColor: this.chartBorderColor[0],
      data: [135, 145, 168, 151, 142, 138, 132],
      fill: false,
      tension: 0
    }


    //============== Data Temperature ============
    this.datatemp01 = {
      id: 1,
      name: 'Temp. Sensor 01',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorTempID: 1,
      sensorTempName: 'Temp. Sensor 01',
      sensorHumidID: 1,
      sensorHumidName: 'Humidity Sensor 01',
      sensorCOID: 1,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(255, 99, 132, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset01, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset01, 0))
    };

    this.datatemp02 = {
      id: 2,
      name: 'Temp. Sensor 02',
      sensorType: 'temp',
      zoneID: 'b',
      zoneName: 'Building Zone B',
      sensorHumidID: 2,
      sensorHumidName: 'Humidity Sensor 02',
      sensorCOID: 3,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(255, 159, 64, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset02, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset02, 0))
    };

    this.datatemp03 = {
      id: 3,
      name: 'Temp. Sensor 03',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 3,
      sensorHumidName: 'Humidity Sensor 03',
      sensorCOID: 2,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(255, 205, 86, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset03, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset03, 0))
    };

    this.datatemp04 = {
      id: 4,
      name: 'Temp. Sensor 04',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 4,
      sensorHumidName: 'Humidity Sensor 04',
      sensorCOID: 4,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(75, 192, 192, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset04, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset04, 0))
    };

    this.datatemp05 = {
      id: 5,
      name: 'Temp. Sensor 05',
      sensorType: 'temp',
      zoneID: 'b',
      zoneName: 'Building Zone B',
      sensorHumidID: 5,
      sensorHumidName: 'Humidity Sensor 05',
      sensorCOID: 5,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(54, 162, 235, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset05, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset05, 0))
    };

    this.datatemp06 = {
      id: 6,
      name: 'Temp. Sensor 06',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: 'Humidity Sensor 06',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset06, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset06, 0))
    };

    this.datatemp07 = {
      id: 7,
      name: 'Temp. Sensor 07',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 7,
      sensorHumidName: 'Humidity Sensor 07',
      sensorCOID: 7,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset07, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset07, 0))
    };

    this.datatemp08 = {
      id: 8,
      name: 'Temp. Sensor 08',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: 'Humidity Sensor 08',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset08, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset08, 0))
    };

    this.datatemp09 = {
      id: 9,
      name: 'Temp. Sensor 09',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: 'Humidity Sensor 09',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset09, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset09, 0))
    };

    this.datatemp10 = {
      id: 10,
      name: 'Temp. Sensor 10',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: 'Humidity Sensor 10',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset10, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset10, 0))
    };

    this.datatemp11 = {
      id: 11,
      name: 'Temp. Sensor 11',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: 'Humidity Sensor 11',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset11, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset11, 0))
    };

    this.datatemp12 = {
      id: 12,
      name: 'Temp. Sensor 12',
      sensorType: 'temp',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: 'Humidity Sensor 12',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(tempDataset12, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(tempDataset12, 0))
    };




    //============== Data Himidity ============
    this.datahumidity01 = {
      id: 1,
      name: 'Humidity Sensor 01',
      sensorType: 'humidity',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: '',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(HumidityDataset01, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(HumidityDataset01, 0))
    };
    this.datahumidity02 = {
      id: 2,
      name: 'Humidity Sensor 02',
      sensorType: 'humidity',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: '',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(HumidityDataset02, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(HumidityDataset02, 0))
    };
    this.datahumidity03 = {
      id: 3,
      name: 'Humidity Sensor 03',
      sensorType: 'humidity',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: '',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(HumidityDataset03, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(HumidityDataset03, 0))
    };
    this.datahumidity04 = {
      id: 4,
      name: 'Humidity Sensor 04',
      sensorType: 'humidity',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: '',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(HumidityDataset04, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(HumidityDataset04, 0))
    };
    this.datahumidity05 = {
      id: 5,
      name: 'Humidity Sensor 05',
      sensorType: 'humidity',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: '',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(HumidityDataset05, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(HumidityDataset05, 0))
    };
    this.datahumidity06 = {
      id: 6,
      name: 'Humidity Sensor 06',
      sensorType: 'humidity',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 6,
      sensorHumidName: '',
      sensorCOID: 6,
      sensorCOName: 'CO<sub>2</sub>',
      chartColor: 'rgba(153, 102, 255, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(HumidityDataset06, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(HumidityDataset06, 0))
    };


    //============== Data CO2 ============
    this.dataCO01 = {
      id: 1,
      name: 'CO\u2082 Sensor 01',
      sensorType: 'co2',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 1,
      sensorHumidName: '',
      sensorCOID: 1,
      sensorCOName: 'CO\u2082 Sensor 01',
      chartColor: 'rgba(255, 99, 132, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(CODataset01, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(CODataset01, 0))
    };
    this.dataCO02 = {
      id: 2,
      name: 'CO\u2082 Sensor 02',
      sensorType: 'co2',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 1,
      sensorHumidName: '',
      sensorCOID: 1,
      sensorCOName: 'CO\u2082 Sensor 01',
      chartColor: 'rgba(255, 99, 132, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(CODataset02, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(CODataset02, 0))
    };
    this.dataCO03 = {
      id: 3,
      name: 'CO\u2082 Sensor 03',
      sensorType: 'co2',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 1,
      sensorHumidName: '',
      sensorCOID: 1,
      sensorCOName: 'CO\u2082 Sensor 01',
      chartColor: 'rgba(255, 99, 132, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(CODataset03, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(CODataset03, 0))
    };
    this.dataCO04 = {
      id: 4,
      name: 'CO\u2082 Sensor 04',
      sensorType: 'co2',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 1,
      sensorHumidName: '',
      sensorCOID: 1,
      sensorCOName: 'CO\u2082 Sensor 01',
      chartColor: 'rgba(255, 99, 132, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(CODataset04, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(CODataset04, 0))
    };
    this.dataCO05 = {
      id: 5,
      name: 'CO\u2082 Sensor 05',
      sensorType: 'co2',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 1,
      sensorHumidName: '',
      sensorCOID: 1,
      sensorCOName: 'CO\u2082 Sensor 01',
      chartColor: 'rgba(255, 99, 132, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(CODataset05, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(CODataset05, 0))
    };
    this.dataCO06 = {
      id: 6,
      name: 'CO\u2082 Sensor 06',
      sensorType: 'co2',
      zoneID: 'a',
      zoneName: 'Building Zone A',
      sensorHumidID: 1,
      sensorHumidName: '',
      sensorCOID: 1,
      sensorCOName: 'CO\u2082 Sensor 01',
      chartColor: 'rgba(255, 99, 132, 0.5)',
      chartMiniShow: true,
      chartShow: true,
      data: this.getChartDataArray(CODataset06, 0),
      dataCurrent: this.getLastData(this.getChartDataArray(CODataset06, 0))
    };


    // ============== Chart Oprtions ===================
    this.optionsMiniBarChart = {
      type: 'bar',
      legend: {
        display: false,
      },
      responsive: true,
      scales: {
        yAxes: [{
          id: 'left-y-axis',
          display: false,
        }],
        xAxes: [{
          display: false,
        }],
      }
    };


    this.miniChartData = [this.datatemp01, this.datatemp02, this.datatemp03, this.datatemp04, this.datatemp05, this.datatemp06, this.datatemp07, this.datatemp08,
    this.datatemp09, this.datatemp10, this.datatemp11, this.datatemp12, this.datahumidity01, this.datahumidity02, this.datahumidity03, this.datahumidity04,
    this.datahumidity05, this.datahumidity06, this.dataCO01, this.dataCO02, this.dataCO03, this.dataCO04, this.dataCO05, this.dataCO06];


  };

  getLastData(data) {
    var dataLength = data.datasets[0].data.length - 1
    var currentData = data.datasets[0].data[dataLength]
    // console.log(data.datasets[0].data[dataLength]);
    return currentData;
  }


  getChartDataArray(mainData, compareData) {
    let chartData = {};
    if (compareData) {
      compareData.yAxisID = 'right-y-axis';
      chartData = {
        labels: this.weekDay,
        datasets: [
          mainData,
          compareData
        ]
      }
    } else {
      mainData.yAxisID = 'left-y-axis';
      chartData = {
        labels: this.weekDay,
        datasets: [
          mainData
        ]
      }
    }
    // console.log(chartData);
    return chartData;
  }

}
